<template>
  <div class="map w-100 d-flex justify-content-center">
    <div
      v-for="room in rooms"
      :key="room.room.id"
      :style="mapStyleObject(room.room)"
      class="border bg-white"
    >
      <template v-for="x in Number(room.room.x_length)">
        <template v-for="y in Number(room.room.y_length)">
          <div
            :key="x.toString() + ',' + y.toString()"
            class="position-relative"
            :class="[
              getPostazione(room, x, y)[0] &&
              getPostazione(room, x, y)[0].risorsa &&
              postazioniOccupate.indexOf(
                getPostazione(room, x, y)[0].risorsa.id
              ) != -1
                ? 'occupato'
                : '',
              getPostazione(room, x, y).length == 0 ||
              (getPostazione(room, x, y).length > 0 &&
                !getPostazione(room, x, y)[0].risorsa)
                ? 'unselectable'
                : '',
              getPostazione(room, x, y).length > 0 &&
              !getPostazione(room, x, y)[0].risorsa
                ? 'blocco'
                : '',
              getPostazione(room, x, y)[0]
                ? getPostazione(room, x, y)[0].shape
                : '',
            ]"
            :style="cellStyle(room, x, y)"
            @click="openModale(getPostazione(room, x, y)[0])"
            @keyup.enter="openModale(getPostazione(room, x, y)[0])"
            >
            <span
              v-if="
                getPostazione(room, x, y).length > 0 &&
                !getPostazione(room, x, y)[0].risorsa
              "
              class="z-100"
            ></span>
            <span
              v-if="
                getPostazione(room, x, y).length > 0 &&
                getPostazione(room, x, y)[0].risorsa
              "
            >
              {{ getPostazione(room, x, y)[0].risorsa.resource_name }}
            </span>
          </div>
          <modal
            @close="infoRisorsa = null"
            v-if="
              getPostazione(room, x, y).length > 0 &&
              getPostazione(room, x, y)[0].risorsa &&
              infoRisorsa == getPostazione(room, x, y)[0].risorsa.id
            "
            :key="x.toString() + ',' + y.toString() + 'modale'"
          >
            <template slot="body">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <span>
                  {{ getPostazione(room, x, y)[0].risorsa.resource_code }}</span
                >
                <span>
                  {{ getPostazione(room, x, y)[0].risorsa.description }}</span
                >
              </div>
            </template>
            <template slot="footer">
        <button
        @click="selectRisorsa(getPostazione(room, x, y)[0])"
        @keyup.enter="selectRisorsa(getPostazione(room, x, y)[0])"
        class="btn btn-primary"
          :class="{ disabled: error }"
        >
          {{$t('buttons.next')}}
        </button>
      </template>
          </modal>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Modal from "../Modal.vue";
//@group Form
export default {
  components: { Modal },
  computed: {
    ...mapState({
      rooms: (state) => state.Entry.servizio.rooms,
      entry: (state) => state.Entry,
    }),
    postazioni() {
      // let rooms = [];
      return Object.values(this.rooms)[0].postazioni;
      // for(let room in Object.entries(this.rooms))
      // {
      //    rooms.push(room);
      // }
      // return rooms;
    },
  },
  data() {
    return {
      postazioniOccupate: null,
      infoRisorsa: null,
    };
  },
  mounted() {
    // this.mapPostazioni();
    this.fetchStatoRisorse();
  },
  methods: {
    mapStyleObject(room) {
      return {
        display: "inline-grid",
        gridTemplateColumns: `repeat(${room.x_length}, minmax(50px,1fr)`,
        gridTemplateRows: `repeat(${room.y_length}, minmax(50px,1fr)`,
      };
    },
    cellStyle(room, x, y) {
      return {
        "grid-area": this.getPostazione(room, x, y)[0]
          ? Number(this.getPostazione(room, x, y)[0].x_position) +
            "/" +
            Number(this.getPostazione(room, x, y)[0].y_position) +
            "/span " +
            this.getPostazione(room, x, y)[0].span_y +
            "/span " +
            this.getPostazione(room, x, y)[0].span_x
          : "",
      };
    },
    ...mapMutations({
      setRisorsa: "Entry/SET_RISORSA",
    }),
    async fetchStatoRisorse() {
      let start_time = this.entry.data
        ? this.entry.data.getTime() / 1000
        : null;
      // + timeZoneOffset,
      let end_time = this.entry.data
        ? new Date(
            this.entry.data.getTime() + this.entry.durata * 1000
          ).getTime() / 1000
        : null;
      let res = await this.$http.get(
        `/api/risorse/mappa/${this.entry.servizio.id}/${start_time}/${end_time}`
      );
      this.postazioniOccupate = Object.values(res.data);
    },
    openModale(risorsa) {
      this.infoRisorsa = risorsa.risorsa.id;
    },
    selectRisorsa(risorsa) {
      this.setRisorsa(risorsa);
      this.$router.push({ name: "DatiUtente" });
    },
    getPostazione(room, x, y) {
      return room.postazioni.filter(
        (e) => e.x_position == x && e.y_position == y
      );
    },
  },
};
</script>

<style>
.resource {
  position: relative;
  display: inline-block;
  border: 1px solid black;

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* margin: 1rem; */
  cursor: pointer;
  font-size: 0.8rem;
}
.occupato {
  background: red;
  cursor: none;
}
/* .room {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: grid;

  gap: 1px 1px;
 
  height: 100%;
  border: 10px solid black;

} */
.door {
  background: white;
  height: 50px;
  width: 50px;
}
.map {
  height: 100%;
  overflow: scroll;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #eee;
  padding: 1rem;
}

.map-cell {
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.unselectable {
  pointer-events: none;
}
.blocco {
  position: relative;
  display: inline-block;

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.porta_sinistra {
  border-left: 10px solid black;
  position: relative;
}
.porta_sinistra::after {
  /* border-left: 10px solid black; */
  content: "ingresso";
  transform: rotate(90deg);
  position: absolute;
  left: -10px;
}
.porta_destra {
  border-right: 10px solid black;
}
.porta_destra::after {
  content: "ingresso";
  transform: rotate(-90deg);

  position: absolute;
}
.porta_basso::after {
  content: "ingresso";
  position: absolute;
}
.porta_alto {
  border-top: 10px solid black;
}
.porta_alto::after {
  content: "ingresso";
  position: absolute;
}
.porta_basso {
  border-bottom: 10px solid black;
}
.schermo {
  position: relative;
  display: flex;
  border-top: 10px solid rgba(116, 116, 253, 0.671);
  margin: 10px;
}
.schermo::after {
  content: "Schermo";
  position: absolute;
}
</style>