var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map w-100 d-flex justify-content-center"},_vm._l((_vm.rooms),function(room){return _c('div',{key:room.room.id,staticClass:"border bg-white",style:(_vm.mapStyleObject(room.room))},[_vm._l((Number(room.room.x_length)),function(x){return [_vm._l((Number(room.room.y_length)),function(y){return [_c('div',{key:x.toString() + ',' + y.toString(),staticClass:"position-relative",class:[
            _vm.getPostazione(room, x, y)[0] &&
            _vm.getPostazione(room, x, y)[0].risorsa &&
            _vm.postazioniOccupate.indexOf(
              _vm.getPostazione(room, x, y)[0].risorsa.id
            ) != -1
              ? 'occupato'
              : '',
            _vm.getPostazione(room, x, y).length == 0 ||
            (_vm.getPostazione(room, x, y).length > 0 &&
              !_vm.getPostazione(room, x, y)[0].risorsa)
              ? 'unselectable'
              : '',
            _vm.getPostazione(room, x, y).length > 0 &&
            !_vm.getPostazione(room, x, y)[0].risorsa
              ? 'blocco'
              : '',
            _vm.getPostazione(room, x, y)[0]
              ? _vm.getPostazione(room, x, y)[0].shape
              : '' ],style:(_vm.cellStyle(room, x, y)),on:{"click":function($event){_vm.openModale(_vm.getPostazione(room, x, y)[0])},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.openModale(_vm.getPostazione(room, x, y)[0])}}},[(
              _vm.getPostazione(room, x, y).length > 0 &&
              !_vm.getPostazione(room, x, y)[0].risorsa
            )?_c('span',{staticClass:"z-100"}):_vm._e(),(
              _vm.getPostazione(room, x, y).length > 0 &&
              _vm.getPostazione(room, x, y)[0].risorsa
            )?_c('span',[_vm._v(" "+_vm._s(_vm.getPostazione(room, x, y)[0].risorsa.resource_name)+" ")]):_vm._e()]),(
            _vm.getPostazione(room, x, y).length > 0 &&
            _vm.getPostazione(room, x, y)[0].risorsa &&
            _vm.infoRisorsa == _vm.getPostazione(room, x, y)[0].risorsa.id
          )?_c('modal',{key:x.toString() + ',' + y.toString() + 'modale',on:{"close":function($event){_vm.infoRisorsa = null}}},[_c('template',{slot:"body"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.getPostazione(room, x, y)[0].risorsa.resource_code))]),_c('span',[_vm._v(" "+_vm._s(_vm.getPostazione(room, x, y)[0].risorsa.description))])])]),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-primary",class:{ disabled: _vm.error },on:{"click":function($event){_vm.selectRisorsa(_vm.getPostazione(room, x, y)[0])},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.selectRisorsa(_vm.getPostazione(room, x, y)[0])}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.next'))+" ")])])],2):_vm._e()]})]})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }